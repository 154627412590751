<template>
  <div class="form-box">
    <ion-grid>
      <ion-row class="ion-text-center">
        <ion-col>
          <img class="logo-login" src="/assets/login/welcome_logo.png">
          <ion-label color="light">
            <span style="color:white;">{{ getEnv('APP_NAME') }}</span>
            <span v-if="getEnv('APP_NAME') == 'ChoiceSocial'" style="font-size:0.7em; color:white;"><br />by
              PikMobile<sup>&reg;</sup></span>
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div>
      <form v-if="resetPass">
        <div class="signup-body space-adjust">
          <ion-input class="login-input" type="email" autocomplete="emai" name="email"
            :placeholder="$t('placeholder.forgotemail')" v-model="email" required="true" autofocus="true"></ion-input>
          <div v-show="error != ''" class="error-block"><ion-note color="light">{{ error }}</ion-note></div>
          <ion-button expand="block" class="login-button" v-on:click="sendreset()">{{ $t('buttons.reset') }}</ion-button>
          <ion-button expand="block" class="help-button" button @click="resetPass = false" target="_blank">{{
            $t('buttons.back') }}</ion-button>

        </div>
        <ion-grid style="padding-top:10px;">
          <ion-row class="ion-text-center small-link">
            <ion-col>
              <ion-button color="clear" button @click="openHowToModal()" target="_blank">{{ $t('buttons.morehelp')
              }}</ion-button>
            </ion-col>

          </ion-row>
        </ion-grid>
      </form>
      <form v-else>
        <ion-input class="login-input" type="username" autocomplete="username" name="username"
          :placeholder="$t('placeholder.loginuser')" v-model="username" required="true"></ion-input>
        <ion-input class="login-input" type="password" autocomplete="password" name="password" v-model="password"
          :placeholder="$t('placeholder.loginpass')" required="true" @keyup.enter="this.login();"></ion-input>
        <div v-show="error != ''" class="error-block"><ion-note color="light">{{ error }}</ion-note></div>
        <ion-button expand="block" class="login-button" v-on:click="this.login()">{{ $t('buttons.login')
        }}</ion-button>
        <ion-grid style="padding-top:10px;">
          <ion-row class="ion-text-center small-link">
            <ion-col>
              <span class="language" @click="resetPass = true">{{ $t('navlinks.forgot')
              }}</span>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
      <ion-grid>
        <ion-row v-show="!isPlatform('hybrid') && !isPlatform('electron')" class="ion-text-center">
          <ion-col v-show="getEnv('ITUNES_URL') != '#'" class="ion-padding-vertical">
            <a :href="getEnv('ITUNES_URL')" target="_blank">
              <img src="/assets/login/apple_app_store.png" />
            </a>
          </ion-col>
          <ion-col v-show="getEnv('PLAYSTORE_URL') != '#'" class="ion-padding-vertical">
            <a :href="getEnv('PLAYSTORE_URL')" target="_blank">
              <img alt='Get it on Google Play' src='/assets/login/en_badge_web_generic.png' />
            </a>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-show="isPlatform('hybrid') || isPlatform('electron')">
        <ion-row class="ion-text-center small-link">
          <ion-col>
            <a :href="getEnv('PRIVACY_PAGE')" target="_blank">
              {{ $t('navlinks.privacy') }}
            </a>
          </ion-col>
          <ion-col v-if="getEnv('MOBILE_TERMS_PAGE')!=''">
            <a :href="getEnv('MOBILE_TERMS_PAGE')" target="_blank">
              {{ $t('navlinks.mobileterms') }}
            </a>
          </ion-col>
          <ion-col>
            <a :href="getEnv('TERMS_PAGE')" target="_blank">
              {{ $t('navlinks.terms') }}
            </a>
          </ion-col>
        </ion-row>
        <ion-row class="ion-text-center">
          <ion-col></ion-col>
          <ion-col>
            <ion-select style="color:white; font-size: 0.8em;" justify="space-between" :label="$t('options.dislang')"
              :aria-label="$t('options.dislang')" @ionChange="changeLocale($event.detail.value)"
              :value="this.$root.$i18n.locale" interface="popover">
              <ion-select-option value="en">English</ion-select-option>
              <ion-select-option value="pt">Portuguese</ion-select-option>
              <ion-select-option value="es">Spanish</ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col></ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</template>
  
<script>
import { IonCol, IonGrid, IonRow, IonButton, IonInput, IonNote, IonLabel, loadingController, isPlatform, alertController, IonSelect, IonSelectOption } from '@ionic/vue';
import '../theme/signup.css';
import { arrowBack } from 'ionicons/icons';
import getEnv from '@/utils/env'
import { Browser } from '@capacitor/browser';

export default {
  name: "LoginContainer",
  props: {
    type: { String, default: 'home' },
    dismissFlag: { Boolean, default: false }
  },
  emits: ['update:dismissFlag'],
  components: { IonCol, IonGrid, IonRow, IonButton, IonInput, IonLabel, IonNote, IonSelect, IonSelectOption },
  data() {
    return {
      username: '',
      password: '',
      error: '',
      email: '',
      loadingIndicator: '',
      resetPass: false
    }
  },
  setup() {
    return {
      getEnv,
      isPlatform,
      arrowBack
    }
  },
  mounted() {
    this.$store.dispatch("header/general", {
      page: this.$t('header.userlogin')
    });
  },
  methods: {
    changeLocale(select) {
      this.$root.$i18n.locale = select;
      return;
    },
    async sendreset() {
      if (this.email == "") {
        this.error = this.$t('errors.emailreq');
      } else {
        await this.presentLoading();

        let response = await this.$store.dispatch("auth/reset", {
          email: this.email,
        });
        if (response) {
          this.loadingIndicator.dismiss();
          if (response['error'] != null) {
            console.log('Error: ' + response['error']);
            console.log(response);
            this.error = response['error'];
            this.presentErrorAlert(response['error']);
          } else {
            this.error = '';
            this.email = '';
            this.presentAlert();
            //console.log('password reset link sent');
          }
        } else {
          // you can add condition here
          this.error = this.$t('errors.unknown');
        }
      }
    },
    async login() {
      if (this.username == "" || this.password == "") {
        this.error = this.$t('errors.useremailreq');
      } else {
        await this.presentLoading();
        let response = await this.$store.dispatch("auth/login", {
          username: this.username,
          password: this.password,
        });

        if (response['error'] != null) {
          this.loadingIndicator.dismiss();
          console.log('Error: ' + response['error']);
          console.log(response);
          if (response['error'] == "Request failed with status code 401") {
            this.error = this.$t('errors.incorrectlogin');
          } else {
            this.error = response['error'];
          }
        } else if (response == true) {
          let profileResponse = await this.$store.dispatch("profile/info");
          if (profileResponse) {
            this.$root.$i18n.locale = this.$store.state.profile.locale;
            this.loadingIndicator.dismiss();
            if (this.type == 'modal') {
              this.$emit('update:dismissFlag', true);
            } else {
              if (this.$store.state.helpers.loginRedirect != '') {
                var loginRedirect = this.$store.state.helpers.loginRedirect;
                this.$store.commit('helpers/setRedirectLogin', '');
                //window.location = loginRedirect;
                this.$router.replace(loginRedirect);
              } else {
                console.log('go home');
                //console.log(this.$store.state.auth.token, 'token');
                this.$router.replace('/home');
              }
            }
          }

        } else {
          // you can add condition here
          this.error = this.$t('errors.unknown');
        }
      }
    },
    async presentAlert() {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: this.$t('alerthead.passlink'),
          message: this.$t('alertmessage.passlink'),
          buttons: [
            {
              text: 'OK',
            },
          ],
        });
      return alert.present();
    },
    async presentErrorAlert(error) {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: this.$t('alerthead.passlinkerror'),
          message: error,
          buttons: [
            {
              text: 'OK',
            },
          ],
        });
      return alert.present();
    },
    async openHowToModal() {
      await Browser.open({ url: getEnv('HELP_LINK') }); 
      //window.open(getEnv('HELP_LINK'));
    },
    async presentLoading() {
      let message = this.$t('loadmessage.loggingin');
      if (this.resetPass) {
        message = this.$t('loadmessage.passlink');
      }
      this.loadingIndicator = await loadingController
        .create({
          cssClass: 'loading-class',
          message: message
        });
      await this.loadingIndicator.present();
    },
  },
};
</script>
  
<style>
.login-button {
  --background: rgba(100, 100, 100, 1.0);
  margin-top: 20px;
}

.error-label {
  padding-top: 25px;
  color: lightgray;
}

.loading-class {
  --background: #222;
  --spinner-color: #fff;

  color: #fff;
}
</style>
  