<template>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="secondary">
          <ion-button @click="closeModal">
            <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen>
      <div id="entry-parent" :class="randomBack()">
      <div id="content-box">
        <SignupContainer type="modal" v-model:dismissFlag="dismissFlag" v-if="type=='signup'" />
        <LoginContainer type="modal" v-model:dismissFlag="dismissFlag" v-if="type=='login'" />
      </div>
    </div>
    </ion-content>
  </template>
  
<script>
  import { IonContent, IonHeader, IonTitle, IonToolbar, IonButton, IonButtons,  modalController, IonIcon } from '@ionic/vue';
  import { closeOutline } from 'ionicons/icons';
  import { defineComponent } from 'vue';
  import SignupContainer from './SignupContainer.vue';
  import LoginContainer from './LoginContainer.vue';
  import getEnv from '@/utils/env'
  
  export default defineComponent({
    name: 'SignupModal',
    props: {
      title: { type: String, default: '' },
      type: { type: String, default: 'signup' },
    },
    data(){
      return {
        dismissFlag: false,
        backVal: 1
      }
    },
    mounted(){
      if(getEnv('APP_NAME') == 'ChoiceSocial'){
        this.backVal = Math.floor(Math.random() * (7 - 1) + 1);
      }
    },
    setup() {
      const isDisabled = false;
      const closeModal = () => {
        modalController.dismiss();
      };
  
      return {
        closeOutline,
        closeModal,
        isDisabled
      }
    },
    methods: {
      randomBack() {
        return 'background-' + this.backVal;
      }
    },
    watch: {
      // whenever question changes, this function will run
      dismissFlag() {
        modalController.dismiss(null, "loggedin");
      },
    },
    components: { IonContent, IonHeader, IonButton, IonButtons, IonTitle, IonToolbar, IonIcon, SignupContainer, LoginContainer },

  });
  </script>
  