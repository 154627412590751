// initial state
import getEnv from '@/utils/env'

const REQUEST_URL = getEnv('REQUEST_URL');

import axios from 'axios';

const state = () => ({
  postData:[],
})

// getters
const getters = {}

// actions
const actions = {
  async updateDate(context) {
    let piklist_id = context.state.postData['id']['piklist_id'];
    return axios.post(REQUEST_URL + "piklist/updatedate",{
      id: piklist_id,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      /* return context.dispatch('sendPhotos', {
        story_id: context.state.postData.id.piklist_id
      }); */
      return res;
    })
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);
      }
      //console.log(error.config);
      return({'error': error.message});
    });
  },
  async createStory(context) {
    var editorChoose = "me";
    /* if(context.state.postData.addGroup!=''){
      editorChoose = "choosegroup";
    }else */
    if(context.state.postData.addFollowers.length>0){
      editorChoose = "choosefollowers";
    }
    var members = [];
    for(var i = 0; i < context.state.postData['addFollowers'].length; i++){
      members.push(context.state.postData['addFollowers'][i].id);
    }

    /* let addGroupId = '';
    if(context.state.postData.addGroup!=''){
      addGroupId = context.state.postData.addGroup.id;
    } */
    
    var editorParams = {
      choose: editorChoose,
      members: members,
      group_id: '',
    };

    var viewerChoose = "";

    if(context.state.postData.visibility==0){
      if(context.state.postData.viewFollowers.length>0){
        viewerChoose = "choosefollowers";
      /* }else if(context.state.postData.viewGroup!=''){
        viewerChoose = "choosegroup"; */
      }else{
        viewerChoose = "me";
      }
    } else if(context.state.postData.visibility==1){
      viewerChoose = "everyone";
    } else if(context.state.postData.visibility==2){
      viewerChoose = "followers";
    } else if(context.state.postData.visibility==3){
      viewerChoose = "pikstore";
    }

    var viewerMembers = [];
    for(var j = 0; j < context.state.postData['viewFollowers'].length; j++){
      viewerMembers.push(context.state.postData['viewFollowers'][j].id);
    }
    /* let viewerGroupId = '';
    if(context.state.postData.viewGroup!=''){
      viewerGroupId = context.state.postData.viewGroup.id;
    } */
    // console.log(subscriptionData);
    var viewerParams = {
      choose: viewerChoose,
      members: viewerMembers,
      group_id: '',
    };

    return axios.post(REQUEST_URL + "piklist/create",{
      name: context.state.postData.name,
      piklock: context.state.postData.piklock,
      visibility: context.state.postData.visibility,
      share_off: context.state.postData.share_off,
      user_group: context.state.postData.viewGroup,
      editor_choose: editorParams,
      viewer_choose: viewerParams
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      },
      timeout: 6000
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      return res.data.id;
      /* return context.dispatch('addEditors', {
        story_id: res.data.id
      }); */ 
      //return true;
    })
    .catch(error => {
      /* if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      } */
      return({'error': error.message});
    });
  },
  /*async addEditors(context, params) {
    var choose = "me";
    if(context.state.postData.addGroup!=''){
      choose = "choosegroup";
    }else if(context.state.postData.addFollowers.length>0){
      choose = "choosefollowers";
    }
    var members = [];
    for(var i = 0; i < context.state.postData['addFollowers'].length; i++){
      members.push(context.state.postData['addFollowers'][i].id);
    }
    return axios.post(REQUEST_URL + "piklist-editor/create",{
      choose: choose,
      members: members,
      group_id: context.state.postData.addGroup.id,
      piklist_id: params.story_id,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(() => {
      return context.dispatch('addViewers', {
        story_id: params.story_id
      });
      // return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async addViewers(context, params) {
    var choose = "";
    //var subscriptionData = null;

    if(context.state.postData.visibility==0){
      if(context.state.postData.viewFollowers.length>0){
        choose = "choosefollowers";
      }else if(context.state.postData.viewGroup!=''){
        choose = "choosegroup";
      }else{
        choose = "me";
      }
    } else if(context.state.postData.visibility==1){
      choose = "everyone";
    } else if(context.state.postData.visibility==2){
      choose = "followers";
    } else if(context.state.postData.visibility==3){
      choose = "pikstore";
      //subscriptionData = context.state.postData.subscriptionData;
    }

    var members = [];
    for(var i = 0; i < context.state.postData['viewFollowers'].length; i++){
      members.push(context.state.postData['viewFollowers'][i].id);
    }

    // console.log(subscriptionData);
    var postParams = {
      choose: choose,
      members: members,
      group_id: context.state.postData.viewGroup.id,
      piklist_id: params.story_id,
    };

    // check for subscription info here
    // if(subscriptionData!=null){
    //  for (const key in subscriptionData) {
    //    // console.log(`${key}: ${subscriptionData[key]}`);
    //    postParams[key] = subscriptionData[key];
    //  }
    //} 

    // console.log(postParams);
    return axios.post(REQUEST_URL + "piklist-viewer/create", postParams,
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(() => {
      return params.story_id;
      //return context.dispatch('sendPhotos', {
      //  story_id: params.story_id
      //}); 
      // console.log(res);
      // return res;
    })
    .catch(error => {
      return({'error': error.message});
    });
  },*/
  async sendPhotos(context, params) {
    if(context.state.postData.photoFiles.length > 0){
      for(var i = 0; i < context.state.postData.photoFiles.length; i++){
        if(i+1 == context.state.postData.photoFiles.length){ // return on the last one
          return context.dispatch('uploadPhotos', {
            story_id: params.story_id,
            photo: context.state.postData.photoFiles[i],
            type: context.state.postData.photoType[i],
            caption: context.state.postData.photoCaption[i],
            total: context.state.postData.photoFiles.length,
            link_url: context.state.postData.link_url,
            link_text: context.state.postData.link_text,
            link_image: context.state.postData.link_image,
            link_video: context.state.postData.link_video,
            current: i
          });
        }else{
          context.dispatch('uploadPhotos', {
            story_id: params.story_id,
            photo: context.state.postData.photoFiles[i],
            type: context.state.postData.photoType[i],
            caption: context.state.postData.photoCaption[i],
            total: context.state.postData.photoFiles.length,
            link_url: context.state.postData.link_url,
            link_text: context.state.postData.link_text,
            link_image: context.state.postData.link_image,
            link_video: context.state.postData.link_video,
            current: i
          });
        }
      }
    }else{
      // console.log('text post: ' + context.state.postData.textPost);
      // console.log('story_id' + params.story_id);
      return context.dispatch('addTextPost', {
        story_id: params.story_id,
        caption: context.state.postData.textPost,
      });
    }
  },
  async uploadPhotos(context, params) {
    // const blob = await fetch(base64).then(res => res.blob());
    // console.log(params);
    // this function now needs to upload data in chunks, specifically the video Blob
    // I think we can pass a nonce that determines w
    const formData = new FormData();
    const base64Data = params.photo;
    const base64 = await fetch(base64Data);
    const blob = await base64.blob();
      if(params.type==1){
        formData.append('uploaded_pik', blob);
        // formData.append('thumb_pik', blob); // hmm we need to resize this client side?
      }else if(params.type==2){
        formData.append('video', blob); // check for this one
      }
      //console.log(params, 'pre-filter');

      let caption = params.caption.split("\n").join("<br>");
      //console.log(caption, 'post-filter');
      
      formData.append('description', caption);
      formData.append('originator_id', '');
      formData.append('pik_path', '0');
      formData.append('piklist_id', params.story_id);
      formData.append('pikd_pik_id', 0);
      formData.append('piklist_order', params.current);
      formData.append('pikable', 0);
      formData.append('pikd_date_time', '0000-00-00 00:00:00');
      formData.append('origin_date', '0000-00-00 00:00:00');
      formData.append('removed_pik', '0000-00-00 00:00:00');
      formData.append('cost', '0.00');
      formData.append('exif', '0');
      formData.append('type', params.type);
      formData.append('duration', 0);
      formData.append('comment', '');
      formData.append('resize', '1');

      if(params.link_url!=undefined) formData.append('link_url', params.link_url);
      if(params.link_text!=undefined) formData.append('link_text', params.link_text);
      if(params.link_image!=undefined) formData.append('link_image', params.link_image);
      if(params.link_video!=undefined) formData.append('link_video', params.link_video);

      return axios.post(REQUEST_URL + "image/create",
      formData,
      {
        headers: {
          'Authorization': context.rootState.auth.token,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        // context.commit('setRequest', res.data);
        // console.log(res);
        //console.log(params.current + ' : ' + params.total);
        return res;
        // if(params.current+1 == params.total){
        //  return res;
        // }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        //console.log(error.config);
        return({'error': error.message});
      });

  },
  async uploadPartial(context, params) {
    // const blob = await fetch(base64).then(res => res.blob());
    // console.log(params);
    // this function now needs to upload data in chunks, specifically the video Blob
    // I think we can pass a nonce that determines w
    const formData = new FormData();
    /* const base64Data = params.photo;
    const base64 = await fetch(base64Data);
    const blob = await base64.blob();
      if(params.type==1){
        formData.append('uploaded_pik', blob);
        // formData.append('thumb_pik', blob); // hmm we need to resize this client side?
      }else if(params.type==2){
        formData.append('video', blob); // check for this one
      } */

      formData.append('video', params.photo); // check for this one
      var caption = params.caption.split("\n").join("<br>");
      formData.append('description', caption);
      formData.append('originator_id', '');
      formData.append('pik_path', '0');
      formData.append('piklist_id', params.story_id);
      formData.append('pikd_pik_id', 0);
      formData.append('piklist_order', params.current);
      formData.append('pikable', 0);
      formData.append('pikd_date_time', '0000-00-00 00:00:00');
      formData.append('origin_date', '0000-00-00 00:00:00');
      formData.append('removed_pik', '0000-00-00 00:00:00');
      formData.append('cost', '0.00');
      formData.append('exif', '0');
      formData.append('type', params.type);
      formData.append('duration', 0);
      formData.append('comment', '');
      formData.append('resize', '1');
      formData.append('file_id', params.file_id);
      formData.append('finalize', params.finalize);

      if(params.link_url!=undefined) formData.append('link_url', params.link_url);
      if(params.link_text!=undefined) formData.append('link_text', params.link_text);
      if(params.link_image!=undefined) formData.append('link_image', params.link_image);
      if(params.link_video!=undefined) formData.append('link_video', params.link_video);

      return axios.post(REQUEST_URL + "image/create-partial",
      formData,
      {
        headers: {
          'Authorization': context.rootState.auth.token,
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        // context.commit('setRequest', res.data);
        // console.log(res);
        //console.log(params.current + ' : ' + params.total);
        return res;
        // if(params.current+1 == params.total){
        //  return res;
        // }
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
        return({'error': error.message});
      });

  },
  async addTextPost(context, params) {
    // no photos just text
    var caption = params.caption.split("\n").join("<br>");
    return axios.post(REQUEST_URL + "image/create", {
      piklist_id: params.story_id,
      description: caption,
      pik_name: 'text.jpg',
      pik_path: '0',
      piklist_order: 0,
      pikable: 0,
      pikd_date_time: '0000-00-00 00:00:00',
      origin_date: '0000-00-00 00:00:00',
      cost: '0.00',
      type: '3',
      exif: '0',
      removed_pik: '0000-00-00 00:00:00',
      link_url: params.link_url,
      link_text: params.link_text,
      link_image: params.link_image,
      link_video: params.link_video,
      link_width: params.link_width,
      link_height: params.link_height
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      //console.log(res);
      return res;
    })
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      //console.log(error.config);
      return({'error': error.message});
    });
  },
  async createRepostStory(context, params) {
    // console.log('create: ' + state.postData);
    return axios.post(REQUEST_URL + "piklist/create",{
      name: params.name,
      piklock: 0,
      visibility: 1,
      share_off: '',
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      // console.log(res);
      return context.dispatch('repostPhoto', {
        post: params.post,
        comment: params.comment,
        story_id: res.data.id
      });
    })
    .catch(error => {
      return({'error': error.message});
    });
  },
  async updateRepostDate(context, params) {
    return axios.post(REQUEST_URL + "piklist/updatedate",{
      id: params.story_id,
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(() => {
      // context.commit('setRequest', res.data);
      return context.dispatch('repostPhoto', {
        story_id:  params.story_id,
        post: params.post,
      });
    })
    .catch(error => {
      //if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      //} else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
      //} else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);
      //}
      //console.log(error.config);
      return({'error': error.message});
    });
  },
  async repostPhoto(context, params) {
    var post = params.post;
    //console.log(params);
    //console.log(post);
    return axios.post(REQUEST_URL + "image/create", {
      piklist_id: params.story_id,
      description: post.description,
      pik_name: post.pik_name,
      originator_id: post.originator_id,
      width: post.width,
      height: post.height,
      pik_path: '0',
      pikd_pik_id: post.id,
      piklist_order: 0,
      pikable: 0,
      pikd_date_time: '0000-00-00 00:00:00',
      origin_date: '0000-00-00 00:00:00',
      cost: '0.00',
      type: post.type,
      comment: params.comment,
      exif: '0',
      duration: 0,
      removed_pik: '0000-00-00 00:00:00',
      link_url: post.link_url,
      link_text: post.link_text,
      link_image: post.link_image,
      link_video: post.link_video
    },
    {
      headers: {
        'Authorization': context.rootState.auth.token,
      }
    })
    .then(res => {
      // context.commit('setRequest', res.data);
      // console.log(res);
      return res;
    })
    .catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        //console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        //console.log('Error', error.message);
      }
      //console.log(error.config);
      return({'error': error.message});
    });

  },
}

// mutations
const mutations = {
  setPostData(state, response) {
    state.postData = response;
  },
  removeStory(state) {
    state.postData['id'] = '';
  },
  setPhotoFiles(state, response) {
    state.postData['photoFiles'] = response;
  },
  setPhotoType(state, response) {
    state.postData['photoType'] = response;
  },
  setPhotoCaption(state, response) {
    state.postData['photoCaption'] = response;
  },
  setUserGroup(state, response) {
    state.postData['viewGroup'] = response;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
