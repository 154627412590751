import { createRouter, createWebHistory, createWebHashHistory } from '@ionic/vue-router';
import Login  from '@/views/Login.vue'
import UserLogin  from '@/views/UserLogin.vue'
import SignupView  from '@/views/Signup.vue'
import ChangePass  from '@/views/ChangePass.vue'
import Tabs  from '@/views/TabsPage.vue'
import Home  from '@/views/Home.vue'
import store from '../store';
import { isPlatform } from '@ionic/vue';
import { App } from '@capacitor/app';

const tabRoutes = [
  {
    path: '/',
    component: Tabs,
    children: [
      {
        path: '/',
        redirect: 'home'
      },
      {
        path: 'u/:username',
        name: 'username',
        component: () => import('@/views/Profile.vue')
      },
      {
        path: 'feed',
        name: 'feed',
        component: () => import('@/views/Feed.vue')
      },
      {
        path: 'home',
        name: 'home',
        component: Home
      },
      {
        path: 'explore',
        name: 'explore',
        component: () => import('@/views/Explore.vue')
      },
      {
        path: 'featured',
        name: 'featured',
        component: () => import('@/views/Featured.vue'),
      },
      {
        path: 'elections',
        name: 'elections',
        component: () => import('@/views/Elections.vue'),
      },
      {
        path: 'menu',
        name: 'menu',
        component: () => import('@/views/More.vue')
      },
      {
        path: 'messages',
        name: 'messages',
        component: () => import('@/views/Messages.vue')
      },
      {
        path: 'saved',
        name: 'saved',
        component: () => import('@/views/Saved.vue')
      },
      {
        path: 'profile/:id',
        name: 'profile',
        component: () => import('@/views/Profile.vue')
      },
      /*{
        path: 'userprofile/:username',
        name: 'userprofile',
        component: () => import('@/views/Profile.vue')
      }, */
      {
        path: 'search/:id',
        name: 'search',
        component: () => import('@/views/Search.vue')
      },
      {
        path: 'searchall',
        name: 'searchall',
        component: () => import('@/views/SearchAll.vue')
      },
      /* {
        path: 'myprofile',
        name: 'myprofile',
        component: () => import('@/views/Profile.vue')
      },*/
      {
        path: 'share',
        name: 'share',
        component: () => import('@/views/Profile.vue')
      },
      {
        path: 'friends/:type/:id',
        name: 'friends',
        component: () => import('@/views/Friends.vue')
      },
      {
        path: 'story/:imageid',
        name: 'story',
        component: () => import('@/views/Story.vue')
      },
      {
        path: 's/:hashid',
        name: 'storyview',
        component: () => import('@/views/Story.vue')
      },
      {
        path: 'groups',
        name: 'groups',
        component: () => import('@/views/Groups.vue')
      },
      {
        path: 'group/:id',
        name: 'groupview',
        component: () => import('@/views/GroupsPage.vue')
      },
      {
        path: 'options',
        name: 'options',
        component: () => import('@/views/Options.vue')
      },
      {
        path: 'messages/:id',
        name: 'message-thread',
        component: () => import('@/views/MessagesThread.vue')
      },
      {
        path: 'notifications',
        name: 'notifications',
        component: () => import('@/views/Notifications.vue')
      },
      {
        path: 'welcome',
        name: 'welcome',
        component: () => import('@/views/Welcome.vue')
      },
      {
        path: '404',
        name: 'PageNotExist',
        component: () => import('@/views/NotFound.vue'),
      },
      {
        path: 'login',
        component: Login
      },
      {
        path: 'user-login',
        component: UserLogin
      },
      {
        path: 'user-login/:autologin',
        component: UserLogin
      },
      {
        path: 'change-pass',
        component: ChangePass
      },
      {
        path: 'signup',
        component: SignupView
      },
      {
        path: '404',
        name: 'PageNotExist',
        component: () => import('@/views/NotFound.vue'),
      },
      {
          path: ":catchAll(.*)", // Unrecognized path automatically matches 404
          redirect: '/404',
      },
    ],
  },
  {
    path: '/404',
    name: 'PageNotExist',
    component: () => import('@/views/NotFound.vue'),
  },
  {
      path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
      redirect: '/404',
  },
  {
    path: '/',
    redirect: '/home'
  },
] 

let routes = [
  /*{
    path: '/',
    component: SplitPane,
    children: [
      
    ],
  },*/
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '/u/:username',
    name: 'username',
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/feed',
    name: 'feed',
    component: () => import('@/views/Feed.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/explore',
    name: 'explore',
    component: () => import('@/views/Explore.vue')
  },
  {
    path: '/featured',
    name: 'featured',
    component: () => import('@/views/Featured.vue'),
  },
  {
    path: '/elections',
    name: 'elections',
    component: () => import('@/views/Elections.vue'),
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/More.vue')
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('@/views/Messages.vue')
  },
  {
    path: '/saved',
    name: 'saved',
    component: () => import('@/views/Saved.vue')
  },
  {
    path: '/profile/:id',
    name: 'profile',
    component: () => import('@/views/Profile.vue')
  },
  /*{
    path: 'userprofile/:username',
    name: 'userprofile',
    component: () => import('@/views/Profile.vue')
  }, */
  {
    path: '/search/:id',
    name: 'search',
    component: () => import('@/views/Search.vue')
  },
  {
    path: '/searchall',
    name: 'searchall',
    component: () => import('@/views/SearchAll.vue')
  },
  /* {
    path: 'myprofile',
    name: 'myprofile',
    component: () => import('@/views/Profile.vue')
  },*/
  {
    path: '/share',
    name: 'share',
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/friends/:type/:id',
    name: 'friends',
    component: () => import('@/views/Friends.vue')
  },
  {
    path: '/story/:imageid',
    name: 'story',
    component: () => import('@/views/Story.vue')
  },
  {
    path: '/embed/:imageid',
    name: 'embed',
    component: () => import('@/views/VideoEmbed.vue')
  },
  {
    path: '/s/:hashid',
    name: 'storyview',
    component: () => import('@/views/Story.vue')
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('@/views/Groups.vue')
  },
  {
    path: '/group/:id',
    name: 'groupview',
    component: () => import('@/views/GroupsPage.vue')
  },
  {
    path: '/options',
    name: 'options',
    component: () => import('@/views/Options.vue')
  },
  {
    path: '/messages/:id',
    name: 'message-thread',
    component: () => import('@/views/MessagesThread.vue')
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/Notifications.vue')
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: () => import('@/views/Welcome.vue')
  },
  {
    path: '/404',
    name: 'PageNotExist',
    component: () => import('@/views/NotFound.vue'),
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/user-login',
    component: UserLogin
  },
  {
    path: '/user-login/:autologin',
    component: UserLogin
  },
  {
    path: '/change-pass',
    component: ChangePass
  },
  {
    path: '/signup',
    component: SignupView
  },
  {
    path: '/404',
    name: 'PageNotExist',
    component: () => import('@/views/NotFound.vue'),
  },
  {
      path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
      redirect: '/404',
  },
]

//let routes = splitPaneRoutes;

if(isPlatform('iphone') || isPlatform('android')){
  routes = tabRoutes;
}

const router = createRouter({
  //history: createWebHistory(import.meta.env.BASE_URL),
  history: isPlatform('electron') ? createWebHashHistory() : createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(async (to) => {
  //console.log(to.path, 'to path');
  //console.log(from.path, 'from path');
  
  if(store.state.auth.token=='') {
    //console.log('no token');
    //var path = window.location.pathname;
    //console.log(window.location.pathname, 'path');
    if(to.path.substring(0,3)=='/s/'){
      return true;
    }else if(to.path.substring(0,3)=='/u/'){
      return true;
    }else if(to.path.substring(0,3)=='/s/' || to.path.substring(0,7)=='/story/'){
      return true;
    }else if(to.path.substring(0,3)=='/u/' || to.path.substring(0,13)=='/userprofile/' || to.path.substring(0,9)=='/profile/'){
      return true;
    }else if(to.path.substring(0,8)=='/search/'){
      return true;
    }else if(to.path=='/user-login'){
      return true;
    }else if(to.path=='/reset-pass'){
      return true;
    }else if(to.path=='/change-pass'){
      return true;
    }else if(to.path=='/signup'){
      return true;
    }else if(to.name=='embed'){
      return true;
    }else if(to.name=='groupview'){
      return true;
    }else{
      if(to.path != '/login'){
        //console.log(to.path, 'go to login');
        return { path: '/login', query: to.query }
      } 
      //if(isPlatform('capacitor')){
        //return true;
        //next('/login');
      //}else{
      //  window.location.href = "https://choicesocial.us/welcome.php";
      //}
    }
    // next('/login');
  }
})

App.addListener('appUrlOpen', event => {
  let regex = /\.com/g;
  let pad = 4;
  let fullPath = '';
  if(import.meta.env['VITE_APP_NAME']=='ChoiceSocial'){
    regex = /\.us/g;
    pad = 3;
  }
  
  let url = event.url;
  const firstPos = url.search(regex);
  fullPath = url.substring(firstPos+pad);
  
  let hasQuery = false;
  const queryParams = {}; 
  const queryString = url.split('?')[1];
  if (queryString) {
    hasQuery = true;
    const pairs = queryString.split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      queryParams[key] = decodeURIComponent(value);
    }
  }
  let slug = { path: fullPath };
  if(hasQuery){
    slug = {
      path: fullPath,
      query: queryParams
    }
  }

  // We only push to the route if there is a slug present
  //console.log(slug, 'full path to push');
  if (fullPath!='') {
    router.push(slug);
  }
});

export default router
