// initial state
import getEnv from '@/utils/env'

const state = () => ({
  title: getEnv('APP_NAME'),
  meta: [
    { name: 'description', content: getEnv('OG_DESC') },
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-title', content: getEnv('APP_NAME') },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'dark' },
    { name: 'og:title', content: getEnv('APP_NAME') },
    { name: 'og:url', content: 'https://' + getEnv('SHARE_HOSTNAME') },
    { name: 'og:description', content:  getEnv('OG_DESC') },
    { name: 'og:site_name', content: getEnv('APP_NAME') },
    { name: 'og:image', content: 'https://' + getEnv('SHARE_HOSTNAME') + '.com/assets/home.jpg' },
    { name: 'og:type', content: 'website' },
    { name: 'og:image:width', content: '1280' },
    { name: 'og:image:height', content: '720' },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:site', content: getEnv('APP_NAME')},
    { name: 'twitter:creator', content: getEnv('APP_NAME') },
    { name: 'twitter:title', content: getEnv('APP_NAME') },
    { name: 'twitter:image', content: 'https://' + getEnv('SHARE_HOSTNAME') + '.com/assets/home.jpg' }
  ],
})

// getters
const getters = {}

// actions
const actions = {
    custom(context, params){
        context.commit("setOpenGraph", {
            title: params.title,
            url: params.url, 
            description: params.description, 
            imageUrl: params.pik_name, 
            imageWidth: params.width, 
            imageHeight: params.height,
            videoUrl: params.video,
            id: params.id
        });
        
    },
    general(context, params){
        let title =  params.page + " - " + getEnv('APP_NAME');
        // set title for generic pages
        /* title = context.dispatch('urlSet', {
            url: params.defaultUrl, 
            title: title
        });*/
        //console.log(params.defaultUrl, 'setting default header');
        context.commit("setDefaults", title);
    },
    /* urlSet(context, params){
        let title = params.title;
        let url = params.url;
        console.log(context.rootState,'test');
        if (url == "/favorites") {
            title = "Favorites - " + title;
        } else if (url == "/home") {
             title = "Home - " + title;
        } else if (url == "/explore") {
            title = " Explore - " + title;
        } else if (url == "/featured") {
            title =  "Featured - " + title;
        } else if (url == "/feed") {
            title =  "Home Feed - " + title;
        } else if (url == "/friends/2/0" || url=='/followers') {
            title =  "Followers - " + title;
        } else if (url == "/friends/1/0" || url=='/following') {
            title =  "Following - " + title;
        } else if (url == "/options") {
            title =  "Options - "  + title;;
        } else if (url == "/notifications") {
            title =  "Notifications - " + title;
        } else if (url == "/user-login") {
            title =  "Login - " + title;
        } else if (url == "/signup") {
            title =  "Sign Up - " + title;
        }
        return title;
    }*/
}

// mutations
const mutations = {
  setDefaults(state, response) {
    state.title = response;
    state.meta = [
        { name: 'description', content: getEnv('OG_DESC') },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-title', content: response },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'dark' },
        { name: 'og:title', content: response },
        { name: 'og:url', content: 'https://' + getEnv('SHARE_HOSTNAME') },
        { name: 'og:description', content: getEnv('OG_DESC') },
        { name: 'og:site_name', content: getEnv('APP_NAME') },
        { name: 'og:image', content: 'https://' + getEnv('SHARE_HOSTNAME') + '/assets/home.jpg' },
        { name: 'og:type', content: 'website' },
        { name: 'og:image:width', content: '1280' },
        { name: 'og:image:height', content: '720' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: getEnv('APP_NAME')},
        { name: 'twitter:creator', content: getEnv('APP_NAME') },
        { name: 'twitter:title', content: response },
        { name: 'twitter:image', content: 'https://' + getEnv('SHARE_HOSTNAME') + '/assets/home.jpg' }
      ]; 
  },
  setOpenGraph(state, response) {
    let description = '';
    let title = getEnv('APP_NAME');
    if(response.description!=null) description = response.description.replace(/(<([^>]+)>)/gi, " ");
    if(response.title!=null)  title = response.title.replace(/(<([^>]+)>)/gi, " ") + ' - ' + getEnv('APP_NAME');
    state.title = title;
    state.meta = [
        { name: 'description', content: description },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-title', content: title },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'dark' },
        { name: 'og:title', content: title },
        { name: 'og:url', content: response.url },
        { name: 'og:description', content: description },
        { name: 'og:site_name', content: getEnv('APP_NAME') },
        { name: 'og:type', content: 'website' },
        { name: 'og:image', content: response.imageUrl },
        { name: 'og:image:width', content: response.imageWidth },
        { name: 'og:image:height', content: response.imageWidth },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: getEnv('APP_NAME')},
        { name: 'twitter:creator', content: getEnv('APP_NAME') },
        { name: 'twitter:title', content: title },
        { name: 'twitter:image', content: response.imageUrl },
      ];
    if(response.videoUrl!=''){
        state.meta.push({ name: 'og:video', content: response.videoUrl });
        state.meta.push({ name: 'og:video:type', content: 'application/x-mpegURL' });
        //state.meta.push({ name: 'og:video', content: 'https://' + getEnv('SHARE_HOSTNAME') + '/embed/' + response.id });
        state.meta.push({ name: 'og:video:width', content: response.imageWidth });
        state.meta.push({ name: 'og:video:height', content: response.imageWidth });
        //state.meta.push({ name: 'twitter:video', content: response.videoUrl });
        state.meta.push({ name: 'twitter:player', content: 'https://' + getEnv('SHARE_HOSTNAME') + '/embed/' + response.id  });
        state.meta.push({ name: 'twitter:player:width', content: response.imageWidth });
        state.meta.push({ name: 'twitter:player:height', content: response.imageWidth });
        
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
