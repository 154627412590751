<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-back-button slot="start"></ion-back-button>
        <ion-title>{{ getEnv('APP_NAME') }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div id="entry-parent" :class="randomBack()">
        <div id="content-box">
          <div class="form-box">
            <ion-grid>
              <ion-row class="ion-text-center">
                <ion-col>
                  <img class="logo-login" src="/assets/login/welcome_logo.png">
                  <ion-label color="light">
                    <span style="color:white;">{{ getEnv('APP_NAME') }}</span>
                    <span v-if="getEnv('APP_NAME') == 'ChoiceSocial'" style="font-size: 0.8em; color:white;"><br />by
                      PikMobile<sup>&reg;</sup></span>
                  </ion-label>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-button style="margin-bottom:10px;" expand="block" class="signup-button" @click="signup()">{{
              $t('buttons.createacct') }}</ion-button>
            <ion-button expand="block" class="login-button" @click="login()">{{ $t('buttons.login') }}</ion-button>
            <ion-grid v-show="!isPlatform('hybrid') && !isPlatform('electron')" class="ion-text-center">
              <ion-row>
                <ion-col v-show="getEnv('ITUNES_URL') != '#'" class="ion-padding-vertical">
                  <a :href="getEnv('ITUNES_URL')" target="_blank">
                    <img src="/assets/login/apple_app_store.png" />
                  </a>
                </ion-col>
                <ion-col class="ion-padding-vertical">
                  <a :href="getEnv('PLAYSTORE_URL')" target="_blank">
                    <img alt='Get it on Google Play' src='/assets/login/en_badge_web_generic.png' />
                  </a>
                </ion-col>
              </ion-row>
            </ion-grid>
            <ion-grid v-show="isPlatform('hybrid') || isPlatform('electron')">
              <ion-row class="ion-text-center small-link">
                <ion-col>
                  <a :href="getEnv('PRIVACY_PAGE')" target="_blank">
                    {{ $t('navlinks.privacy') }}
                  </a>
                </ion-col>
                <ion-col v-if="getEnv('MOBILE_TERMS_PAGE') != ''">
                  <a :href="getEnv('MOBILE_TERMS_PAGE')" target="_blank">
                    {{ $t('navlinks.mobileterms') }}
                  </a>
                </ion-col>
                <ion-col>
                  <a :href="getEnv('TERMS_PAGE')" target="_blank">
                    {{ $t('navlinks.terms') }}
                  </a>
                </ion-col>
              </ion-row>
              <ion-row class="ion-text-center">
                <ion-col></ion-col>
                <ion-col>
                  <ion-select style="color:white; font-size: 0.8em;" justify="space-between"
                    :label="$t('options.dislang')" :aria-label="$t('options.dislang')"
                    @ionChange="changeLocale($event.detail.value)" :value="this.$root.$i18n.locale" interface="popover">
                    <ion-select-option value="en">English</ion-select-option>
                    <ion-select-option value="pt">Portuguese</ion-select-option>
                    <ion-select-option value="es">Spanish</ion-select-option>
                  </ion-select>
                </ion-col>
                <ion-col></ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
      </div>
    </ion-content>
    <FooterContainer v-show="!isPlatform('hybrid') && !isPlatform('electron')" />
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonCol, IonGrid, IonRow, IonButton, isPlatform, IonLabel, IonHeader, IonToolbar, IonBackButton, IonTitle, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineAsyncComponent } from 'vue'
import '../theme/signup.css';
import getEnv from '@/utils/env'
const FooterContainer = defineAsyncComponent(() => import('@/components/FooterContainer.vue'))

export default {
  name: "LoginView",
  components: { IonContent, IonPage, IonCol, IonGrid, IonRow, IonButton, IonLabel, FooterContainer, IonHeader, IonToolbar, IonBackButton, IonTitle, IonSelect, IonSelectOption },
  setup() {
    return {
      getEnv,
      isPlatform
    }
  },
  beforeCreate() {
    if (this.$route.query.lang != undefined) {
      this.$root.$i18n.locale = this.$route.query.lang;
    }
  },
  mounted() {
    this.$store.dispatch("header/general", {
      page: this.$t('header.login')
    });
    if(getEnv('APP_NAME') == 'ChoiceSocial'){
      this.backVal = Math.floor(Math.random() * (7 - 1) + 1);
    }
  },
  data() {
    return {
      backVal: 1
    }
  },
  methods: {
    signup() {
      this.$router.push('/signup');
    },
    login() {
      this.$router.push('/user-login');
    },
    changeLocale(select) {
      this.$root.$i18n.locale = select;
      return;
    },
    randomBack() {
      return 'background-' + this.backVal;
    }
  }
};
</script>

<style scoped>
.signup-button {
  --background: rgba(40, 40, 40, 1.0);
}

.login-button {
  --background: rgba(100, 100, 100, 1.0);
}

ion-select {
  --placeholder-color: #971e49;
  --placeholder-opacity: 1;
  width: 100%;
  justify-content: center;
}

</style>
