<template>
  <ion-page>
    <ion-header :translucent="true">
      <HeaderContainer title="Feeds" />
      <ion-toolbar v-if="getEnv('APP_NAME') == 'ChoiceSocial'">
        <ion-segment v-model="feedSwitch" class="home-feed-col" @ionChange="getNewsfeed()" value="featured">
          <ion-segment-button value="home">
            <ion-label>{{ $t("tabs.following") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="featured">
            <ion-label>{{ $t("tabs.foryou") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="groupfeed">
            <ion-label>{{ $t("tabs.groups") }}</ion-label>
          </ion-segment-button>
          <ion-segment-button v-if="getEnv('APP_NAME') == 'FrankSocial'" value="elections">
            <ion-label>{{ $t("tabs.elections") }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-grid class="ion-no-padding">
        <ion-row>
          <ion-col class="ion-no-padding home-feed-col">
              <FeedContainer v-if="this.$store.state.auth.token != ''" :streamName="feedSwitch"
              :stories="newsfeed" v-model:refreshFeed="refreshFeed" v-model:networkTimeout="timeout" />
          </ion-col>
          <ion-col class="ion-padding-horizontal sidebar">
            <div style="position:sticky; top: 15px; height: 85vh; overflow: scroll;">
              <div v-if="topLikeProfiles.length>0">
                <div style="margin:15px 5px;"><strong>Trending Profiles</strong></div>
                <div style="padding:0; margin:15px 5px;" class="story-header"
                  v-for="(friend, friendIndex) in topLikeProfiles.slice(0, 4)" :key="friendIndex">
                  <img style="cursor:pointer;" @click="selectFriend(friend)" class="post-profile-pic"
                    v-if="friend.profile_pic != '' && friend.profile_pic != undefined"
                    :src="profilepath + friend.profile_pic">
                  <img style="cursor:pointer;" @click="selectFriend(friend)" class="post-profile-pic"
                    src="/assets/spyguy.png" v-if="friend.profile_pic == '' || friend.profile_pic == undefined">
                  <div class="story-header-content" style="padding-left:0px;">
                    <div class="story-header-post" style="word-break: break-all; cursor:pointer;" @click="selectFriend(friend)">
                      <span style="font-size:0.9em; font-weight: bold;  word-wrap:break-word;">{{ friend.username
                      }}</span><svg v-show="friend.certified == 1" xmlns="http://www.w3.org/2000/svg"
                        class="ionicon s-ion-icon verified-sm" viewBox="0 0 512 512">
                        <title>{{ getEnv('APP_NAME') }} {{ $t('buttons.verified') }}</title>
                        <path
                          d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm108.25 138.29l-134.4 160a16 16 0 01-12 5.71h-.27a16 16 0 01-11.89-5.3l-57.6-64a16 16 0 1123.78-21.4l45.29 50.32 122.59-145.91a16 16 0 0124.5 20.58z">
                        </path>
                      </svg>
                    </div>
                    <div class="user-name-header" style="font-size:0.9em;">{{ friend.name }}</div>
                  </div>
                </div>
              </div>
              <hr>
              <div v-if="topLikePosts.length>0">
                <div style="margin:15px 5px;"><strong>Trending Posts</strong></div>
                <ion-grid>
                  <ion-row style="cursor: pointer;" @click="selectStory(story)"
                    v-for="(story, storyIndex) in topLikePosts.slice(0, 5)" :key="storyIndex">
                    <ion-col size="7" class="ion-padding-bottom">
                      <span style="font-size: 0.9em; font-weight:bold;">{{ story.first_name }}</span><br />
                      <div class="caption-box-sm"><span style="font-size:0.9em" v-html="story.description"></span></div>
                    </ion-col>
                    <ion-col class="ion-padding-bottom">
                      <img style="float:right; cursor:pointer;" class="story-thumb-pic-md"
                        v-if="story.pik_name != '' && story.pik_name != undefined"
                        :src="path + '/piks/' + story.pik_name">
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
              <hr>
              <div v-if="hashtagRecent!=undefined">
                <div style="margin:5px;"><strong>Trending Topics</strong></div>
                <div v-for="(hashtag, hashtagIndex) in hashtagRecent" :key="hashtagIndex"
                  @click="selectHashtag(hashtagIndex)">
                  <div style="cursor:pointer; word-break: break-all; margin: 15px 5px;">#{{ hashtagIndex }}</div>
                </div>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-fab id="posting-button" v-if="this.$store.state.helpers.postToggle == '' && this.$store.state.auth.token != ''" vertical="bottom" horizontal="end"
        slot="fixed">
        <ion-fab-button @click="openModal('post')">
          <ion-icon :icon="pencil"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>
<script>
import { IonContent, IonLabel, IonPage, modalController, IonFab, IonFabButton, IonIcon, IonSegment, IonSegmentButton, IonGrid, IonCol, IonRow, isPlatform, IonHeader, IonToolbar } from '@ionic/vue';
import { defineAsyncComponent } from 'vue'
import getEnv from '@/utils/env'
import { pencil } from 'ionicons/icons';

const FeedContainer = defineAsyncComponent(() =>
  import('@/components/FeedContainer.vue'))

const PostModal = defineAsyncComponent(() =>
  import('@/components/posts/CreatePost.vue'))

const WhatsNewModal = defineAsyncComponent(() =>
  import('@/components/WhatsNewModal.vue'))

import HeaderContainer from '@/components/HeaderContainer.vue';
import { version, versionElectron, versionAndroid, versionApple } from '../../package'

/* const RightBarContainer = defineAsyncComponent ( () =>
import('@/components/RightBarContainer.vue'))*/

export default {
  name: 'TabView',
  components: {
    FeedContainer,
    IonContent,
    IonPage,
    IonFab,
    IonFabButton,
    IonIcon,
    HeaderContainer,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonGrid,
    IonCol,
    IonRow,
    IonHeader,
    IonToolbar
  },
  setup() {
    return {
      getEnv,
      pencil
    }
  },
  data() {
    return {
      refreshFeed: false,
      timeout: false,
      report: false,
      feedSwitch: '',
      profilepath: getEnv('AWS_URL') + getEnv('PHOTO_BUCKET') + '/profile/',
      path: getEnv('AWS_URL') + getEnv('PHOTO_BUCKET'),
      thisVersion: version,
    }
  },
  async ionViewDidEnter() {
    this.$store.dispatch("header/general", {
      page: this.$t('header.home')
    });
    if(getEnv('APP_NAME') == 'FrankSocial'){
      this.feedSwitch = 'featured';
    }else{
      this.feedSwitch = 'home';
    }

    if (!isPlatform('iphone') && !isPlatform('android')) {
      this.$store.dispatch("explore/hashtags");
      this.$store.dispatch("explore/topLikeProfiles");
      this.$store.dispatch("explore/topLikePosts");
    }

    
    if (isPlatform('hybrid') && isPlatform('ios')) {
      this.thisVersion = versionApple;
    } else if (isPlatform('hybrid') && isPlatform('android')) {
      this.thisVersion = versionAndroid;
    } else if (isPlatform('electron')) {
      this.thisVersion = versionElectron;
    }
    //console.log(this.thisVersion, 'this version');
    //console.log(this.$store.state.profile.shownew, 'new announce');
    this.getNewsfeed();

    if(this.$store.state.profile.shownew!=this.thisVersion){
      this.openWhatsNewModal();
    }
  },
  methods: {
    async openModal() {
      if (this.$store.state.auth.token != '') {
        let title = this.$t('modaltitle.createpost');
        const modal = await modalController
          .create({
            component: PostModal,
            cssClass: 'modal-custom-class',
            componentProps: {
              title: title,
              share_link: '',
              report: this.report
            }
          })
        this.refreshFeed = false;
        await modal.present();

        const dismissed = await modal.onDidDismiss();
        if (dismissed != undefined) {
          this.refreshFeed = true;
          this.report = false;
        }
      }
    },
    async openWhatsNewModal() {
      if (this.$store.state.auth.token != '') {
        const modal = await modalController
          .create({
            component: WhatsNewModal,
            cssClass: 'modal-custom-class',
            componentProps: {
              title: 'What\'s New',
            }
          })
        this.refreshFeed = false;
        await modal.present();

        const dismissed = await modal.onDidDismiss();
        if (dismissed != undefined) {
          // set the shownew to the current version of the app
          this.$store.commit('profile/setShowWhatsNew', this.thisVersion);
        }
      }
    },
    /* showWelcome() {
      let showIt = false;
      if (this.$store.state.auth.token != '' && getEnv('INFLUENCER_FEED') == 0) {
        if (this.$store.state.pikstream.responseData != null && this.$store.state.pikstream.responseData.length == 0) {
          showIt = true;
        }
      }
      return showIt;
    }, */
    selectFriend(friend) {
      if (friend.userid != null) {
        this.$router.push({ name: 'profile', params: { id: friend.userid } });
      } else if (friend.user_name != null) {
        this.$router.push({ name: 'username', params: { username: friend.user_name } });
      }
    },
    selectStory(story) {
      this.$router.push({ name: 'story', params: { imageid: story.image_id } });
    },
    selectHashtag(hashtag) {
      var hashtagString = hashtag.replace('#', '');
      this.$store.dispatch("helpers/hashtagPosts", {
        search: hashtagString,
        totalList: 50,
        currentPage: 0,
        update: false
      });
      this.$router.push({ name: 'search', params: { id: hashtag.toLowerCase() } });
    },
    async getNewsfeed(){
      if (this.feedSwitch == 'featured') {
        //console.log('featured');   
        if (this.$store.state.pikstream.recommendResponseData == null) {
          let response = await this.$store.dispatch("pikstream/getStream", {
            streamName: 'featured',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.recommend,
            last_id: this.$store.state.pikstream.lastId.recommend,
            userid: ''
          });
          if(response!=undefined){
            if(response.error != undefined){
              console.log('there was an error1', response.error);
              this.timeout = true;
            }
          }
        }
      }else if (this.feedSwitch == 'home') {
        if (this.$store.state.pikstream.followResponseData == null) {
          let response = await this.$store.dispatch("pikstream/getStream", {
            streamName: 'home',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.follow,
            last_id: this.$store.state.pikstream.lastId.follow,
            userid: ''
          });
          if(response!=undefined){
            if(response.error != undefined){
              console.log('there was an error 2', response.error);
              this.timeout = true;
            }
          }
        } 
      } else if (this.feedSwitch == 'groupfeed') {
        //console.log('groupfeed');   
        if (this.$store.state.pikstream.groupFeedResponseData == null) {
          let response = await this.$store.dispatch("pikstream/getStream", {
            streamName: 'groupfeed',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.groupfeed,
            last_id: this.$store.state.pikstream.lastId.groupfeed,
            userid: ''
          });
          if(response!=undefined){
            if(response.error != undefined){
              console.log('there was an error3', response.error);
              this.timeout = true;
            }
          }
        }
      } else if (this.feedSwitch == 'elections') {
        //console.log('elections');   
        if (this.$store.state.pikstream.electionResponseData == null) {
          let response = await this.$store.dispatch("pikstream/getStream", {
            streamName: 'elections',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.election,
            last_id: this.$store.state.pikstream.lastId.election,
            userid: ''
          });
          if(response!=undefined){
            if(response.error != undefined){
              console.log('there was an error4', response.error);
              this.timeout = true;
            }
          }
        } 
      }
    }
  },
  computed: {
    newsfeed(){
      if (this.feedSwitch == 'featured') {
        if (this.$store.state.pikstream.recommendResponseData != null) {
          return this.$store.state.pikstream.recommendResponseData;
        }
      }else if (this.feedSwitch == 'home') {
        if (this.$store.state.pikstream.followResponseData != null) {
          return this.$store.state.pikstream.followResponseData;
        }
      } else if (this.feedSwitch == 'groupfeed') {
        //console.log('groupfeed');   
        if (this.$store.state.pikstream.groupFeedResponseData != null) {
          return this.$store.state.pikstream.groupFeedResponseData;
        }
      } else if (this.feedSwitch == 'elections') {
        if (this.$store.state.pikstream.electionResponseData != null) {
          return this.$store.state.pikstream.electionResponseData;
        }
      }
      return [];
    },
    /* electionFeed() {
        if (this.$store.state.pikstream.electionResponseData == null) {
          this.$store.dispatch("pikstream/getStream", {
            streamName: 'elections',
            filter: '',
            imageid: '',
            storyid: '',
            total_list: this.$store.state.pikstream.totalList.election,
            last_id: this.$store.state.pikstream.lastId.election,
            userid: ''
          });
          return [];
        } else {
          return this.$store.state.pikstream.electionResponseData;
        }
    }, */
    reporter() {
      return this.$store.state.profile.reporter;
    },
    hashtagRecent() {
      return this.$store.state.explore.hashtagData;
    },
    topLikeProfiles() {
      return this.$store.state.explore.likeProfileData;
    },
    topLikePosts() {
      return this.$store.state.explore.likePostData;
    },
  }

}
</script>
<style>
@media only screen and (min-width: 992px) {
  .showMobile {
    display: none;
  }
}</style>
